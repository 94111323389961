import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isNumber } from '../../Validations';
import { topLeft, topRight, bottomLeft, bottomRight } from './utils';
import { UserDataContext, DropdownOptionsContext } from '../../../../Context';
import { checkActiveDatesHospital } from '../../../../utils';
import { filterActiveProducts, isValidDate } from '../../../../utils';
import TopSection from './TopSection';
import CommentSection from './CommentSection';
import BottomSection from './BottomSection';
import { dropdownCodes } from '../../../../dropdownCodes';

const {
  diag71,
  eventType3,
  eventType4,
  intent0,
  intent1,
  intent2,
  intent3,
  prod0000,
  race3,
} = dropdownCodes;

const MainCase = (props) => {
  const {
    caseId,
    currentForm,
    formData,
    formErrors,
    formWarnings,
    hospitalType,
    prodList,
    setButtonType,
    setFormData,
    setFormErrors,
    setAdverseDrugData,
    setAssaultData,
    setMotorVehicleData,
    wordWarnings,
    setWordWarnings,
    setUnsavedChanges,
    setProdList,
  } = props;
  const options = useContext(DropdownOptionsContext);
  const [dropdownOptions, setDropdownOptions] = useState(options);
  const [dateChanged, setDateChanged] = useState(false);
  const { userData, setUserData } = useContext(UserDataContext);
  const { existingCaseId } = useParams();
  const [showTooltipsCase, setShowTooltipsCase] = useState(false);
  const { mainCaseOptions } = options;

  const onChange = (e, name) => {
    setUnsavedChanges(true);
    let value;
    if (e.target) {
      value = e.target.value;
    } else {
      value = e;
    }
    value = value.replace(/[^\x00-\x7F]/g, '');
    switch (name) {
      case 'age':
        if (isNumber(value))
          setFormData((prevState) => ({ ...prevState, [name]: value }));
        setAssaultData((prevState) => ({
          ...prevState,
          patientPeer: '',
          cps: '',
        }));
        break;
      case 'caseNumber':
        if (isNumber(value))
          setFormData((prevState) => ({ ...prevState, [name]: value }));
        break;
      case 'diagnosis1':
        if (value !== diag71)
          setFormData((prevState) => ({ ...prevState, diagnosisOther1: '' }));
        break;
      case 'diagnosis2':
        if (value !== diag71)
          setFormData((prevState) => ({ ...prevState, diagnosisOther2: '' }));
        break;
      case 'intent':
        if (value === intent0 || value === intent3) {
          setAdverseDrugData((prevState) => ({
            ...prevState,
            eventType: '',
          }));
        }
        if (value === intent1) {
          setAdverseDrugData((prevState) => ({
            ...prevState,
            eventType: eventType4,
          }));
        }
        if (value === intent2) {
          setAdverseDrugData((prevState) => ({
            ...prevState,
            eventType: eventType3,
          }));
        }
        break;
      case 'product1':
      case 'product2':
      case 'product3':
        if (!value.startsWith('1519') || !value.startsWith('1901')) {
          setMotorVehicleData((prevState) => ({
            ...prevState,
            collision: '',
            carSeat: '',
          }));
        }
        if (
          (name === 'product1' && value.startsWith('0000')) ||
          (name === 'product1' && value.startsWith('7878'))
        )
          setFormData((prevState) => ({
            ...prevState,
            product2: prod0000,
            product3: prod0000,
          }));
        if (name === 'product2' && value.startsWith('0000'))
          setFormData((prevState) => ({
            ...prevState,
            product3: prod0000,
          }));
        break;
      case 'race':
        if (value !== race3)
          setFormData((prevState) => ({ ...prevState, raceOther: '' }));
        break;
      case 'treatmentDate':
        if (value && isValidDate(value)) {
          if (
            checkActiveDatesHospital(
              userData.hospital.neissAIPActiveDates,
              value
            )
          ) {
            if (setUserData) {
              setUserData((prevState) => ({
                ...prevState,
                hospitalType: 'NEISS-AIP',
              }));
              const prodList = filterActiveProducts(
                mainCaseOptions.caseProduct,
                value,
                'NEISS-AIP'
              );
              setDropdownOptions((prevState) => ({
                ...prevState,
                caseProduct: prodList,
              }));
              setProdList(prodList);
              setDateChanged(true);
            }
          } else {
            if (setUserData) {
              setUserData((prevState) => ({
                ...prevState,
                hospitalType: 'NEISS',
              }));
              const prodList = filterActiveProducts(
                mainCaseOptions.caseProduct,
                value,
                'NEISS'
              );
              setDropdownOptions((prevState) => ({
                ...prevState,
                caseProduct: prodList,
              }));
              setProdList(prodList);
              setDateChanged(true);
            }
          }
        }
        break;
      default:
        break;
    }
    setButtonType('submit');
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
  };

  const toggleCaseTooltips = (e) => {
    e.preventDefault();
    setShowTooltipsCase(!showTooltipsCase);
  };

  const sectionProps = {
    caseId,
    formErrors,
    formWarnings,
    formData,
    onChange,
    setWordWarnings,
    wordWarnings,
    hospitalType,
    dateChanged,
    setDateChanged,
    setFormData,
    setFormErrors,
    showTooltipsCase,
    toggleCaseTooltips,
  };
  useEffect(() => {
    if (existingCaseId && !prodList && formData.treatmentDate) {
      const prodList = filterActiveProducts(
        options.mainCaseOptions.caseProduct,
        formData.treatmentDate,
        'NEISS-AIP'
      );
      setProdList(prodList);
    }
  }, [existingCaseId, prodList, options, formData, setProdList]);

  return (
    <div
      className="list-tab-contents"
      aria-live="polite"
      aria-hidden={currentForm === 'mainCase' ? 'false' : 'true'}
    >
      <h1 className="ut-only-sr">NEISS Case Form</h1>
      <form 
        autoComplete="off"
        className={`case-form ${
          showTooltipsCase === false ? 'tooltips--hidden' : ''
        }`}
      >
        <TopSection {...sectionProps} topLeft={topLeft} topRight={topRight} />
        <CommentSection {...sectionProps} />
        <BottomSection
          {...sectionProps}
          bottomLeft={bottomLeft}
          bottomRight={bottomRight}
          options={dropdownOptions}
        />
      </form>
    </div>
  );
};

export default MainCase;
