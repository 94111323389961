import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  Fragment,
} from 'react';
import { useMsal } from '@azure/msal-react';
import { UserDataContext } from '../../Context';
import DetailTableRow from './DetailTableRow';
import {
  AlertModal,
  ErvWarningModal,
  MODAL_DEFAULT,
  modalPresets,
} from '../../Components/CustomComponents/Modals';
import { isQuarterStart, formatMonth } from '../../utils';
import {
  formatQuarter,
  getErvsDay,
  saveErvs,
  saveErvsQuarter,
  validateMonthlyForm,
} from './utils';
import { request } from '../../api';

const Ervs = ({ setUnsavedChanges }) => {
  const { instance, accounts } = useMsal();
  const { userData } = useContext(UserDataContext);
  const [disableSubmitDailyLogButton, setDisableSubmitDailyLogButton] =
    useState(true);
    const [handleConfirmZeroChecked, setHandleConfirmZeroChecked] = useState(false);
  const [
    disableSubmitQuarterTotalsButton,
    setDisableSubmitQuarterTotalsButton,
  ] = useState(true);
  const [alertModal, setAlertModal] = useState(modalPresets().default);
  const [ervWarningModal, setErvWarningModal] = useState(MODAL_DEFAULT);
  const [quarters, setQuarters] = useState(null);
  const [quartersHaveChanged, setquartersHaveChanged] = useState(false);
  const [monthlyErvs, setMonthlyErvs] = useState(null);
  const [monthSelected, setMonthSelected] = useState();
  const [monthDetails, setMonthDetails] = useState(null);
  const [monthDetailsHaveChanged, setmonthDetailsHaveChanged] = useState(false);
  const { hospital } = userData;
  const { groupId } = hospital;
  const columns = [1,2,3]

  const args = useMemo(
    () => ({
      hospitalId: groupId,
    }),
    [groupId]
  );

  const handleAlerts = (modalPreset) => {
    setAlertModal(modalPreset);
  };

  const handleConfirmCaseCheckbox = () => {
    setHandleConfirmZeroChecked(!handleConfirmZeroChecked)
  };

  useEffect(() => {
    if (monthDetailsHaveChanged && handleConfirmZeroChecked) {
      setDisableSubmitDailyLogButton(!disableSubmitDailyLogButton)
    } else {
      setDisableSubmitDailyLogButton(true)
    }
  }, [monthDetailsHaveChanged, handleConfirmZeroChecked])

  const handleErvChange = (e) => {
    const { name, value } = e.target;
    const id = name.substring(0, 10);
    let tempArray = [...monthDetails];
    const index = tempArray.findIndex((x) => x.treatmentDate === id);
    tempArray[index].numberOfErvs = value;
    setMonthDetails([...tempArray]);
    setmonthDetailsHaveChanged(true);
  };

  const handleConfirmZeroChange = (e) => {
    const { name } = e.target;
    const id = name.substring(0, 10);
    const index = monthDetails.findIndex((x) => x.treatmentDate === id);
    let tempArray = monthDetails;
    tempArray[index].zeroCasesConfirmed = !tempArray[index].zeroCasesConfirmed;
    setMonthDetails([...tempArray]);
    setmonthDetailsHaveChanged(true);
  };

  const handleMonthSelected = (period) => {
    if (!monthDetailsHaveChanged) {
      getErvsDay({
        args,
        month: period,
        handleAlerts,
        setMonthDetails,
        setMonthSelected,
      });
    } else {
      setErvWarningModal({
        open: true,
        period: period,
      });
    }
    setDisableSubmitDailyLogButton(true);
    setHandleConfirmZeroChecked(false);
    setmonthDetailsHaveChanged(false);
  };

  const handleQuarterlyTotalsChanged = (e) => {
    const { value, name } = e.target;
    const index = quarters.findIndex((x) => x.quarterEnd === name);
    let tempArray = quarters;
    tempArray[index].ervUserTotal = value;
    setQuarters([...tempArray]);
    setDisableSubmitQuarterTotalsButton(false);
    setquartersHaveChanged(true);
  };

  const submitDailyLogForm = () => {
    validateMonthlyForm(monthDetails, setMonthDetails);
    let formIsValid = monthDetails.findIndex((x) => x.isValid === false);
    if (formIsValid === -1) {
      saveErvs({
        args,
        monthDetails,
        setDisableSubmitQuarterTotalsButton,
        handleAlerts,
        setmonthDetailsHaveChanged,
        setQuarters,
      });
    } else {
      setAlertModal({
        open: true,
        error: true,
        loading: false,
        message:
          'Daily log changes NOT saved. Please correct errors and try again.',
      });
    }
    setDisableSubmitDailyLogButton(true);
    setHandleConfirmZeroChecked(false);
  };

  useEffect(() => {
    if (monthDetailsHaveChanged === false && quartersHaveChanged === false) {
      setUnsavedChanges(false);
    } else {
      setUnsavedChanges(true);
    }
  });

  // get quarterly data and then populate local quarterly & month states
  useEffect(() => {
    const ac = new AbortController();
    const fetchQuarters = async () => {
      try {
        if (!quarters) {
          const data = await request({
            ...args,
            def: 'ervsByQuarter',
            handleAlerts,
            method: 'GET',
          });
          let tempQuartersArray = [];
          let tempMonthsArray = [];
          let runningTotal = 0;
          for (let i = 0; i < data.length; i++) {
            let tempQuarterObj = {};
            tempQuarterObj.quarterEnd = data[i].quarterEnd;
            tempQuarterObj.ervSum = data[i].ervSum;
            tempQuarterObj.ervOthers = data[i].ervOthers;
            tempQuarterObj.ervUserTotal = data[i].ervUserTotal;
            tempQuartersArray.push(tempQuarterObj);
            // pull out each month from quarters and populate months state
            let tempArray = data[i].months;
            for (let i2 = 0; i2 < tempArray.length; i2++) {
              let tempMonthsObj = {};
              tempMonthsObj.month = tempArray[i2].month;
              tempMonthsObj.numberOfErvs = tempArray[i2].numberOfErvs;
              tempMonthsObj.isQuarterStart = isQuarterStart(
                tempArray[i2].month
              );

              runningTotal += parseInt(tempArray[i2].numberOfErvs);
              tempMonthsObj.qTotal = runningTotal;
              tempMonthsArray.push(tempMonthsObj);
              if (tempMonthsObj.isQuarterStart) {
                runningTotal = 0;
              }
              tempMonthsObj = {};
            }
          }
          setQuarters([...tempQuartersArray]);
          setMonthlyErvs([...tempMonthsArray]);
          getErvsDay({
            args,
            month: monthSelected || data[0].months[0].month,
            handleAlerts,
            setMonthDetails,
            setMonthSelected,
          });
        }
      } catch (err) {
        console.log('ERROR:', err.message);
      }
    };
    fetchQuarters();
    return () => {
      ac.abort();
    };
  }, [
    accounts,
    args,
    groupId,
    instance,
    monthSelected,
    quarters,
    setAlertModal,
  ]);
  return (
    <>
      <section className="card">
        <h2>Emergency Room Visits (ERVs) Daily Log for {hospital.name}</h2>
        <p>
          <b>Note:</b>{' '} 
            Case count updates on the ERVs page may be delayed up to 15 minutes from the time cases are submitted on the dashboard.
        </p>

        <div className="flex accounting-flex-container">
          <div className="flex__child accounting-flex-container__left">
            {/* ** main left col ** */}
            <table className="table table--compact accounting-select-table">
              <caption className="table__caption">Quarter 1</caption>
              <thead>
                <tr className="table__head">
                  <th className="table__heading">Quarter</th>
                  <th className="table__heading">Month</th>
                  <th className="table__heading">#ERVs</th>
                </tr>
              </thead>
              <tbody>
                {monthlyErvs && monthlyErvs.length > 0 && (
                  <>
                    {monthlyErvs.map((period) => (
                      <Fragment key={period.month}>
                        <tr
                          className={`table__row " + ${
                            period.month === monthSelected
                              ? 'table__row--highlight'
                              : ''
                          }`}
                          onClick={() => {
                            handleMonthSelected(period.month);
                          }}
                          tabIndex="0"
                        >
                          <td className="table__cell">
                            {formatQuarter(period.month, false)}
                          </td>
                          <td className="table__cell">
                            {formatMonth(period.month, true)}
                          </td>

                          <td className="table__cell">{period.numberOfErvs}</td>
                        </tr>
                        {period.isQuarterStart && (
                          <>
                            <tr>
                              <td className="table__cell"></td>
                              <td className="table__cell table__cell--subtotal">
                                Total
                              </td>
                              <td className="table__cell table__cell--subtotal">
                                {period.qTotal}
                              </td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                            </tr>
                          </>
                        )}
                      </Fragment>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex__child accounting-flex-container__right">
            {/*  ** main right col ** */}
            {monthSelected &&
              <h3 className="caption ut-align-center">
                Details - {formatMonth(monthSelected, false)}
              </h3>
            }

            <div className="flex flex--column accounting-results-container">
              {/*  ** start vertical flex grid ** */}
              <div className="flex__child">
                {/*  ** top vertical flex child with two tables ** */}
                <div className="flex flex--space-around">
                  {/*  ** three columns containing three tables to right ** */}
                  {monthDetails && monthDetails.length > 0 && columns.map(column => 
                    <div key={column} className="flex__child">
                      <table className="table table--compact accounting-results-table">
                        <thead>
                          <tr className="table__head">
                            <th className="table__heading">TX Date</th>
                            <th className="table__heading">#Cases</th>
                            <th className="table__heading">#ERVs</th>
                          </tr>
                        </thead>
                        <tbody>
                          {monthDetails
                            .filter((item, index) => (index < (10 * column) && index >= (10 * (column-1))) || (index >= 30 && column === 3))
                            .map((period) => (
                              <Fragment key={period.treatmentDate}>
                                <DetailTableRow
                                  period={period}
                                  handleErvChange={handleErvChange}
                                  handleConfirmZeroChange={
                                    handleConfirmZeroChange
                                  }
                                />
                              </Fragment>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/*  ** end three-columns containing right tables ** */}
                </div>
              </div>
              <div className='accounting-results-container__confirm'>
              <label>
                  <input type='checkbox' onChange={handleConfirmCaseCheckbox} checked={handleConfirmZeroChecked} />
                    <span>I have reviewed the case counts and ERV counts for each date displayed above and I attest to their accuracy and completeness.</span>
                </label>
              </div>
              <div className="flex__child accounting-results-container__bottom">
                {/*   ** bottom vertical flex child with button ** */}
                <button
                  className="button button--primary"
                  disabled={disableSubmitDailyLogButton}
                  onClick={submitDailyLogForm}
                >
                  Submit Daily Log Changes
                </button>
              </div>
            </div>
            {/*   ** end vertical flex grid ** */}
          </div>
        </div>
        {/*   ** end main two-column flex grid ** */}
      </section>

      {/*  ************* ERVS TOTALS SECTION ********************* */}
      <section className="card card--erv-totals">
        <h2>ERVs Quarterly Totals</h2>

        <p>
          <b>ERV Sum</b> is your total ERVs as reflected on the table above.
        </p>
        <p>
          <b>Other’s ERV Sum</b> is the total ERVs for this hospital entered by
          other NEISS users.
        </p>

        <table className="table">
          <thead>
            <tr className="table__head">
              <th className="table__heading ut-align-left">Period</th>
              <th className="table__heading">ERV Sum</th>
              <th className="table__heading">Other’s ERV Sum</th>
              <th className="table__heading">Final ERV Total</th>
            </tr>
          </thead>
          <tbody>
            {quarters && quarters.length > 0 && (
              <>
                {quarters.map((quarter, index) => (
                  <Fragment key={quarter.quarterEnd}>
                    <tr
                      className={`table__row ${
                        index % 2 === 1 ? 'table__row--zebra' : ''
                      }`}
                    >
                      <td className="table__cell ut-align-left">
                        {formatQuarter(quarter.quarterEnd, true)}
                      </td>
                      <td className="table__cell">{quarter.ervSum}</td>
                      <td className="table__cell">{quarter.ervOthers}</td>
                      <td className="table__cell">
                        <label htmlFor="inputid" className="ut-only-sr">
                          2020 Quarter 4 final ERVs total
                        </label>
                        <input
                          type="number"
                          name={quarter.quarterEnd}
                          className={
                            'input input--regular ' +
                            (!quarter.ervUserTotal ? 'input--error' : '')
                          }
                          min="0"
                          max="99999999"
                          value={quarter.ervUserTotal}
                          onChange={handleQuarterlyTotalsChanged}
                          onWheel={(e) => e.target.blur()}
                        />
                      </td>
                    </tr>
                    <tr
                      className={
                        'table__row table__row--error ' +
                        (!quarter.ervUserTotal
                          ? 'table__row--error-visible'
                          : '')
                      }
                    >
                      <td
                        className="table__cell table__cell--error"
                        colSpan="4"
                      >
                        You have not yet entered your ERV count for this
                        quarter.
                        <br /> Please obtain this information and enter it as
                        soon as possible.
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </>
            )}
          </tbody>
        </table>
        <br />
        <button
          className="button button--primary"
          disabled={disableSubmitQuarterTotalsButton}
          onClick={() =>
            saveErvsQuarter({
              args,
              quarters,
              handleAlerts,
              setquartersHaveChanged,
            })
          }
        >
          Submit ERVs Quarterly Totals
        </button>
      </section>
      <ErvWarningModal
        args={args}
        modal={ervWarningModal}
        setModal={setErvWarningModal}
        setMonthDetails={setMonthDetails}
        setMonthSelected={setMonthSelected}
        handleAlerts={handleAlerts}
      />
      <AlertModal modal={alertModal} closeModal={handleAlerts} />
    </>
  );
};

export default Ervs;
