import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { UserDataContext, OfflineModeContext } from '../../Context';
import { calculateOOW } from '../../Pages/Dashboard/utils';
import { onSave } from '../../Pages/NewCase/Validations/saveAndValidate';
import { saveToDb } from '../../db';
import { formatData } from '../../Pages/NewCase/Validations/utils';
import { isValidDate } from '../../utils';

const formatOfflineCaseData = async ({ formData, formList, mainCaseData }) => {
  const {
    adverseDrugData,
    assaultData,
    childPoisoningData,
    firearmInjuryData,
    motorVehicleData,
    selfInflictedData,
    workRelatedData,
  } = formData;

  let specialStudiesList = [];
  formList.forEach(async ({ id }) => {
    let formattedContent;
    switch (id) {
      case 'firearmInjury':
        formattedContent = await formatData(firearmInjuryData);
        specialStudiesList.push({
          type: 'firearm injuries',
          content: { ...formattedContent },
        });
        break;
      case 'workRelated':
        formattedContent = await formatData(workRelatedData);
        specialStudiesList.push({
          type: 'NIOSH work injuries',
          content: { ...formattedContent },
        });
        break;
      case 'childPoisoning':
        formattedContent = await formatData(childPoisoningData);
        specialStudiesList.push({
          type: "children's poisoning",
          content: { ...formattedContent },
        });
        break;
      case 'selfInflicted':
        formattedContent = await formatData(selfInflictedData);
        specialStudiesList.push({
          type: 'self-inflicted injuries',
          content: { ...formattedContent },
        });
        break;
      case 'adverseDrug':
        formattedContent = await formatData(adverseDrugData);
        specialStudiesList.push({
          type: 'adverse drug event',
          content: { ...formattedContent },
        });
        break;
      case 'assault':
        formattedContent = await formatData(assaultData);
        specialStudiesList.push({
          type: 'assaults',
          content: { ...formattedContent },
        });
        break;
      case 'motorVehicle':
        formattedContent = await formatData(motorVehicleData);
        specialStudiesList.push({
          type: 'NHTSA non-crash motor vehicle injuries',
          content: { ...formattedContent },
        });
        break;
      default:
        break;
    }
  });

  const formattedMainCase = await formatData(mainCaseData);

  if (specialStudiesList.length > 0) {
    return {
      ...formattedMainCase,
      specialStudies: [...specialStudiesList],
    };
  } else {
    return {
      ...formattedMainCase,
    };
  }
};

const SaveButton = ({ saveArgs, prodList, lastUpdateableDate, toggleDeleteModal, toggleVictimIdButtonErrors }) => {
  const [isOow, setIsOowW] = useState(false);
  const [OowChecked, setoowChecked] = useState(false);
  const {
    handleAlerts,
    formData,
    formList,
    setButtonType,
    setUnsavedChanges,
    options,
  } = saveArgs;
  const { mainCaseData, firearmInjuryData } = formData;
  const { caseNumber, treatmentDate } = mainCaseData;
  const { dateInjured } = firearmInjuryData;
  const { userData, setUserData } = useContext(UserDataContext);
  const { offlineMode } = useContext(OfflineModeContext);
  const { oowValid } = userData;
  const { existingCaseId } = useParams();

  let indexedData;
  let formatTreatmentDate;

  // format treatmentDate to yyyy-mm-dd to compare against lastUpdateableDate.
  if (treatmentDate) {
      formatTreatmentDate = new Date(treatmentDate).toISOString().split('T')[0];
  }

  // assign a random number to the case that's used to determine when to generate a victim id.
  if (mainCaseData.caseRate === undefined) {
    // random number: 0 <= num < 1, with two decimal places
    mainCaseData.caseRate = Math.round(Math.random() * 100) / 100;
  }

  const disableInputCheck = () => {
    if (
      !caseNumber ||
      !treatmentDate ||
      (treatmentDate && !isValidDate(treatmentDate)) ||
      (dateInjured && !isValidDate(dateInjured))
    ) {
      return true;
    }
    if (existingCaseId && !oowValid && isOow) {
      return calculateOOW(formData.mainCaseData.treatmentDate);
    }
    return false;
  };

  useEffect(() => {
    if (mainCaseData.treatmentDate && existingCaseId && !OowChecked) {
      setIsOowW(calculateOOW(mainCaseData.treatmentDate));
      setoowChecked(true);
    }
    setUnsavedChanges(false);
  }, [
    existingCaseId,
    mainCaseData.treatmentDate,
    isOow,
    OowChecked,
    setUnsavedChanges,
  ]);

  useHotkeys(
    'alt + ctrl + s, cmd + ctrl + s',
    async (event, handler) => {
      switch (handler.key) {
        case 'alt+ctrl+s':
        case 'cmd+ctrl+s':
          if (!disableInputCheck()) {
            const args = {
              ...saveArgs,
              options: {
                ...options,
                mainCaseOptions: {
                  ...options.mainCaseOptions,
                  caseProduct: prodList,
                },
              },
            };
            if (offlineMode) {
              const mainCaseData = await onSave({
                ...args,
                validateOnly: true,
              });
              indexedData = await formatOfflineCaseData({
                formData,
                formList,
                mainCaseData,
              });
              saveToDb({
                caseNumber: caseNumber,
                caseData: indexedData,
                setButtonType,
                setUnsavedChanges,
                handleAlerts,
              });
            } else {
              const summary = await onSave({
                ...args,
                validateOnly: offlineMode,
              });
              // update context with new summary numbers
              setUserData((prev) => {
                return {
                  ...prev,
                  hospital: {
                    ...prev.hospital,
                    ...summary,
                  },
                  hospitalData: prev.hospitalData.map((hData) => {
                    if (hData.groupId === prev.hospital.groupId) {
                      return {
                        ...hData,
                        ...summary,
                      };
                    }
                    return hData;
                  }),
                };
              });
            }
          }
          break;
        default:
          break;
      }
    },
    { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] }
  );
  return (
    <div className="case-form__save-button-wrapper">
      {existingCaseId && (formatTreatmentDate > lastUpdateableDate) && (
        <button
          className="button button--secondary button--secondary-warning ut-margin-right-1-em"
          onClick={toggleDeleteModal}
        >
          Delete Case
        </button>
      )}
      <button
        type="button"
        className="save-button button button--primary"
        onClick={async (e) => {
          const args = {
            ...saveArgs,
            options: {
              ...options,
              mainCaseOptions: {
                ...options.mainCaseOptions,
                caseProduct: prodList,
              },
            },
          };
          toggleVictimIdButtonErrors();
          setUnsavedChanges(false);

          if (offlineMode) {
            const mainCaseData = await onSave({ ...args, validateOnly: true });
            indexedData = await formatOfflineCaseData({
              formData,
              formList,
              mainCaseData,
            });
            saveToDb({
              caseNumber: caseNumber,
              caseData: indexedData,
              setButtonType,
              setUnsavedChanges,
              handleAlerts,
            });
          } else {
            // get latest summary numbers from the server
            const summary = await onSave({
              ...args,
              validateOnly: offlineMode,
            });
            setUnsavedChanges(false);

            // update context with new summary numbers
            setUserData((prev) => {
              return {
                ...prev,
                hospital: {
                  ...prev.hospital,
                  ...summary,
                },
                hospitalData: prev.hospitalData.map((hData) => {
                  if (hData.groupId === prev.hospital.groupId) {
                    return {
                      ...hData,
                      ...summary,
                    };
                  }
                  return hData;
                }),
              };
            });
          }
        }}
        disabled={disableInputCheck()}
      >
        <span className="save-button-label">Save NEISS Case</span>
      </button>
    </div>
  );
};

const ContinueButton = ({ resetForm, setUnsavedChanges }) => {
  useHotkeys(
    'alt + ctrl + c, cmd + ctrl + c',
    (event, handler) => {
      switch (handler.key) {
        case 'alt+ctrl+c':
        case 'cmd+ctrl+c':
          resetForm();
          break;
        default:
          break;
      }
    },
    { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] }
  );

  useEffect(() => {
    setUnsavedChanges(false);
  }, []);
  
  return (
    <div className="case-form__save-button-wrapper">
      <button
        type="button"
        className="save-button button button--primary"
        onClick={resetForm}
      >
        <span className="save-button-label">Continue to next case</span>
      </button>
    </div>
  );
};

export { SaveButton, ContinueButton };
